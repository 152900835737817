.custom-container {
  height: 50vh;
  margin: 5%;

  display: flex;
  justify-content: center;
  align-items: center;
}

.firma {
  display: flex;
  flex-direction: column;
  text-align: center;
  margin: 20px;
}
.custom-label {
  width: 200px;
  border-top: 1px solid black;
}
.custom-span {
  font-size: 12px;
}

// Botones para los reglamentos (SSU Y PASANTIA)

.cancelarboton {
  background-color: #db0303;
  color: #ffffff;
  border-color: #db0303;
  width: 110px;
  height: 40px;
  border: 1px 1px 1px 1px solid #db0303;
  display: flex;
  justify-content: center;
  align-items: center;
  &:hover {
      background-color: #ff3c56;
    border: none; 
  } 
}

.actulizarboton {
  background-color: #041147;
  color: #ffffff;
  border-color: #041147;
  width: 110px;
  height: 40px;
  border: 1px 1px 1px 1px solid #041147;
  display: flex;
  justify-content: center;
  align-items: center;
  &:hover {
      background-color: var(--tb-blue-uapa-text-emphasis) !important;
    border: none; 
  }   
}