
.table {
    width: 100%; 
    tbody {
      tr {
        // Estilos de hover de las filas
        &:hover {
          background-color: #dee2e6; // Cambia el color de fondo al pasar el mouse sobre una fila
        }
  
  
      }
    }
    .table tbody tr.first-row {
      background-color: transparent;
      font-weight: normal;
    }
    .table-responsive{
      overflow-x: auto;
    }
  }
  .btnojo{
    background-color: #041147; // Cambia este valor por el color que desees
    color: #ffffff; // Color del texto
    border-color: #041147; // Color del borde si lo deseas
    width: 40px;
    height: 40px;
    border: 1px 1px 1px 1px solid #041147;
    display: flex;
    justify-content: center;
    align-items: center;
    &:hover {
        background-color: var(--tb-blue-uapa-text-emphasis) !important; // Cambia este valor por el color de hover deseado
      border: none; 
    }  
  }

  .imgaviso {
    width:950px; /* Ancho deseado */
    height: 550px; /* Altura deseada */
    border-radius: 10px;
    border-color: #041147;
    border: 2px solid #e86e0a; /* Color y grosor del borde */
 
    transition: border-color 1s, box-shadow 0.3s; /* Transición suave de los cambios */
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.3); /* Color y desenfoque de la sombra */
    
}
.imgaviso:hover {
  border-color: #ff9d00; /* Cambio de color del borde al pasar el mouse */
  box-shadow: 0px 0px 10px rgba(3, 57, 115, 0.5); /* Cambio de sombra al pasar el mouse */
}

@media (max-width: 730px) {
  .imgaviso {
    width: 90%;
    height: auto;
  }
}


