.btnver {
  background-color: #041147;
  color: #ffffff;
  border-color: #041147;
  width: 40px;
  height: 40px;
  border: 1px 1px 1px 1px solid #041147;
  display: flex;
  justify-content: center;
  align-items: center;
  &:hover {
    background-color: var(--tb-blue-uapa-text-emphasis) !important;
    border: none;
  }
}

.table-container {
  overflow-x: scroll;
  width: 100%;
  margin: 0 auto;
  padding: 0 1rem;
}

.table {
  margin-top: 1rem;
}

.box-header {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.search-box-container{
  justify-content: end;
  display: flex;
  flex-direction: row;
}
.search-box{
  width: 50%;
}
.Export-Button{
  margin-left: 10px;
}
@media (max-width: 1285px){
  .search-box{
    width: 100%;
    max-width: 240px;
  }
}
// Estilos para pantallas pequeñas (móviles, tablet)
@media (max-width: 797px) {
  .card .card-header,
  .card .card-header .box-header {
    flex-direction: column;
    width: 100%;
  }
  .search-box-container{
    flex-direction: column;
    max-width: 2000px;
    width: 100%;
    margin-top: 10px;
  }
  .Export-Button{
    margin-left: 0px;
  }
  .search-box{
    width: 100%;
    max-width: 2000px;
  }

  .table-nowrap th,
  .table-nowrap td {
    white-space: normal !important;
  }

  .table-responsive {
    overflow-x: auto;
  }

  table.table-custom-effect {
    width: 100%;
    margin-bottom: 1rem;
    color: blue-uapa;
    border-collapse: collapse;
    border-spacing: 0;
  }

  thead.table-light {
    display: none;
  }

  tbody {
    display: block;
  }

  tr {
    display: block;
    margin-bottom: 1.5rem;
    border: 1px solid #dee2e6;
    border-radius: 0.25rem;
  }

  th,
  td {
    display: block;
    padding: 0.5rem;
    border-top: none;
    border-bottom: 1px solid #dee2e69f;
    text-align: left !important;
    word-wrap: break-word;
  }

  th {
    font-weight: 600;
  }

  td[data-column]:before {
    content: attr(data-column);
    float: left;
    font-weight: 600;
    text-transform: uppercase;
    margin-right: 0.5rem;
  }

  td {
    padding-left: 35%;
  }

  .search-box {
    margin-bottom: 1rem;
  }

  .search {
    width: 100%;
  }

  .btn-orange-uapa {
    width: 100%;
  }

  .btnver {
    width: 100%;
    padding: 4px 10px;
    margin-top: -5px;
  }

  .pagination {
    justify-content: center;
  }

  //Para las imagenes o videos del modal y la tabla de evidencias en métricas
  .doc-style {
    width: 65% !important;
    height: auto !important;
    margin-top: 0.375rem;
    margin-bottom: 0.375rem;
  }
}