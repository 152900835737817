// Estilos para el boton de la vista de la tabla de solicitud de estado
.btn-personalizate {
  background-color: #041147;
  color: #ffffff;
  border-color: #041147;
  width: 40px;
  height: 40px;
  border: 1px 1px 1px 1px solid #041147;
  display: flex;
  justify-content: center;
  align-items: center;
  &:hover {
    background-color: var(
      --tb-blue-uapa-text-emphasis
    ) !important;
    border: none;
  }
}

// Estilos para la tabla de solicitud de estado
table {
  width: 100%; 
  border-collapse: collapse; 

  // Estilos para las celdas de la tabla
  td,
  th {
    border: 1px solid #dee2e6;
    padding: 8px;
    text-align: center;
    vertical-align: middle;
  }
  .fixed-btn-personalizate {
    position: absolute;
    right: 10px;
    width: 100px;
  }
}

.table button {
  padding: 10px 15px;
}

@media (max-width: 767px) {
  .btn-personalizate {
    width: 100%;
  }
  .btneliminar, .btneditar {
    width: 100%;
  }
}


// Estilos del parrafo y el boton de enviar, deshacer del componente llamado (DocumentationRequest.tsx)
.parrafo {
  color: #4a4a4ca0;
}

.botonenviar {
  background-color: #041147;
  color: #ffffff;
  border-color: #041147;
  width: 100px;
  height: 40px;
  border: 1px 1px 1px 1px solid #041147;
  &:hover {
    background-color: var(
      --tb-blue-uapa-text-emphasis
    ) !important; 
    border: none;
  }
}
.botondeshacer {
  background-color: #fa7900;
  color: #ffffff;
  border: 1px 1px 1px 1px solid #fa7900;
  border-color: #fa7900;
  width: 100px;
  height: 40px;
  margin-right: 1%;
  &:hover {
    background-color: #ff9101;
    border: none;
  }
}

// Componente modalbotones
.botonrechazar {
  background-color: #db0303;
  color: #ffffff;
  border: 1px 1px 1px 1px solid;
  border-color: #db0303;
  width: 200px;
  height: 40px;
  &:hover {
    background-color: #ff3c56;
    border: none;
  }
}

.btnenviar-modal {
  background-color: #041147; 
  color: #ffffff; 
  border-color: #041147; 
  width: 200px;
  height: 40px;
  border: 1px 1px 1px 1px solid #041147;
  &:hover {
    background-color: var(
      --tb-blue-uapa-text-emphasis
    ) !important; 
    border: none;
  }
}

@media  (max-width: 465px){
  .botonrechazar, .btnenviar-modal {
    width: 100%;
  }
}

//Para la pagina de enviar las evidencias (DocumentationRquest)
.dropzoneStyles {
  border: 2px dashed #eeeeee;
  border-radius: 4px;
  padding: 20px;
  text-align: center;
  cursor: pointer;
  margin-top: 5px;
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  z-index: 0;
}

.media-items {
  max-width: 100%;
  max-height: 14rem;
  margin: 0.5rem;
  object-fit: contain;
  z-index: 10;
}

.btn-eliminar {
  background-color: #db0303;
  border: 1px solid #db0303;
  border-color: #db0303;
  color: #ffffff;
  position: absolute;
  top: 2;
  right: -6px;
  z-index: 25;
  &:hover {
    background-color: #ff3c56;
    border: 1px solid transparent;
  }
}