.arrow-navtabs {
  .nav-link {
    &:hover:not(.active) {
      color: var(--tb-orange-uapa) !important;
      text-decoration-line: underline;
    }

    &.active,
    &.done {
      background-color: var(--tb-blue-uapa) !important;
      color: white;
    }
  }
}

.botoncerrar {
  background-color: #041147; // Cambia este valor por el color que desees
  color: #ffffff; // Color del texto
  border-color: #041147; // Color del borde si lo deseas
  width: 150px;
  height: 40px;
  border: 1px 1px 1px 1px solid #041147;
  display: flex;
  justify-content: center;
  align-items: center;
  &:hover {
    background-color: var(
      --tb-blue-uapa-text-emphasis
    ) !important; // Cambia este valor por el color de hover deseado
    border: none;
  }
}

.actulizarboton {
  background-color: #041147; // Cambia este valor por el color que desees
  color: #ffffff; // Color del texto
  border-color: #041147; // Color del borde si lo deseas
  width: 110px;
  height: 40px;
  border: 1px 1px 1px 1px solid #041147;
  display: flex;
  justify-content: center;
  align-items: center;
  &:hover {
    background-color: var(
      --tb-blue-uapa-text-emphasis
    ) !important; // Cambia este valor por el color de hover deseado
    border: none;
  }
}

.botoneditar {
  background-color: #041147; // Cambia este valor por el color que desees
  color: #ffffff; // Color del texto
  border-color: #041147; // Color del borde si lo deseas
  width: 110px;
  height: 40px;
  border: 1px 1px 1px 1px solid #041147;
  display: flex;
  justify-content: center;
  align-items: center;
  &:hover {
    background-color: var(
      --tb-blue-uapa-text-emphasis
    ) !important; // Cambia este valor por el color de hover deseado
    border: none;
  }
}

.link {
  color: #ffffff;
  text-decoration: none;
}

.cancelarboton {
  background-color: #db0303;
  color: #ffffff;
  border-color: #db0303;
  width: 110px;
  height: 40px;
  border: 1px 1px 1px 1px solid #db0303;
  display: flex;
  justify-content: center;
  align-items: center;
  &:hover {
    background-color: #ff3c56;
    border: none;
  }
}

.botoncontra {
  background-color: #fa7900;
  color: #ffffff;
  border-color: #fa7900;
  width: 170px;
  height: 40px;
  border: 1px 1px 1px 1px solid #fa7900;
  display: flex;
  justify-content: center;
  align-items: center;
  &:hover {
    background-color: #ff9101;
    border: none;
  }
}

.btnnuevo {
  background-color: #4d4d4d;
  color: #ffffff;
  width: 130px;
  height: 40px;
  border: 1px 1px 1px 1px solid #4d4d4d;
  border-color: #4d4d4d;
  margin-right: auto;
  &:hover {
    background-color: #fa7900;
    border-color: transparent;
    color: #ffffff;
    border: none;
  }
}

.linknuevo {
  color: #ffffff;
  margin-right: auto;
  &:hover {
    background-color: #fa7900;
    border-color: transparent;
    color: #ffffff;
  }
}

.ActualizarUser {
  background-color: #041147;
  color: #ffffff;
  border-color: #041147;
  width: 150px;
  height: 40px;
  border: 1px 1px 1px 1px solid #041147;

  &:hover {
    background-color: var(--tb-blue-uapa-text-emphasis) !important;
    border: none;
  }
}

.btneditar {
  background-color: #ffffff;
  color: #fa7900;
  border-color: #fa7900;
  width: 40px;
  height: 40px;
  border: 1px 1px 1px 1px solid #fa7900;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 5%;
  margin-right: 5%;
  &:hover {
    background-color: #ff9101;
    border: none;
  }
}

.btneliminar {
  background-color: #ffffff;
  color: #db0303;
  border-color: #db0303;
  width: 40px;
  height: 40px;
  border: 1px 1px 1px 1px solid #db0303;
  display: flex;
  justify-content: center;
  align-items: center;
  &:hover {
    background-color: #db0303;
    border: none;
  }
}
.btnguardar {
  background-color: #041147;
  color: #ffffff;
  border-color: #041147;
  width: 100%;
  height: 40px;
  border: 1px 1px 1px 1px solid #041147;
  justify-content: center;

  &:hover {
    background-color: var(--tb-blue-uapa-text-emphasis) !important;
    border: none;
  }
}

.form-btn-container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 1rem;
}

.list-group-container {
  height: 175px;
  max-height: 175px;
  overflow-y: auto;
}


@media (max-width: 500px) {
  .avatar-md {
    width: 72px;
    height: auto;
    min-width: 72px;
  }
}
@media (max-width: 350px){
  .card{
    overflow:hidden;
    text-overflow: ellipsis;
  }
  .infoProfile{
    flex-direction: column;
    align-items: center;
    margin: 0, auto;
    text-align: center;
  }
  .text-muted{
    padding: 10px; 
  }
  body{
    height: auto;
    min-width: 350px;
  }
}