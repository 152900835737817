
.custom-page-content
{
    background: linear-gradient(45deg, #041147, #fff );
    height: 100vh;
}

#container
{
    position: absolute;
    top: 10%;
    left: 10%;
    right: 10%;
    bottom: 10%;
    border-radius: 10%;
    display: flex;
    justify-content: center;
    align-items: center;
    background: url('/assets/images/auth/p404.png'), #041147;
    box-shadow: 0 15px 30px rgba(0, 0, 0, 0.5);
}

#container .content
{
    max-width: 600px;
    text-align: center;
}

#container .content h2
{
    font-size: 16vw;
    color: #fff;
    line-height: 1em;
    background: none;
    display: flex;
    justify-content: center;

}

#container .content h4
{
    position: relative;
    font-size: 1.5em;
    margin-bottom: 20px;
    color: #fff;
    background: none;
    font-weight: 300;
    padding: 10px 20px;
    display: inline-block;
    justify-content:center;
}

#container .content p
{
    color: #fff;
    font-size: 1.2em;
}

#container .content a 
{
    position: relative;
    display: inline-block;
    padding: 10px 25px;
    background: #fa7900;
    color: #fff;
    text-decoration: none;
    margin-top: 25px;
    border-radius: 25px;
    border-bottom: 4px solid #fa7900;
}