//Para truncar el text en los elementos de configuracion

.element{
    width: 200px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

//Para truncar el text en el perfilDropdown

.profile-element{
    width: 95px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}