.rounded-pill {
    background-color:var(--tb-orange-uapa) !important;
    border: none !important;
    color: #ffffff !important;
}

.rounded-pill:hover {
    background-color: var(--tb-orange-uapa-text-emphasis) !important;
    color: #041147 !important;
}

.custom-button { 
    background-color:var(--tb-orange-uapa) !important;
    border: none !important;
}

.custom-button:hover { 
    background-color: var(--tb-orange-uapa-text-emphasis) !important;
    border: none !important;
}

.custom-button-back { 
    background-color:var(--tb-blue-uapa)!important;
    color: #ffffff !important;
    border: none !important;
}

.custom-button-back:hover { 
    background-color: var(--tb-blue-uapa-text-emphasis) !important;
    border: none !important;
}

.regulation:hover {
    border-bottom: 1px solid var(--tb-blue-uapa-text-emphasis);
}
 
.form-control1 {
    flex-direction: row-reverse; // Cambiar el orden de los elementos a la derecha
    width: 97.5%;
    border-color: #cccc;
    border-radius: 5px;
    margin-left: 7.5px; // Ajusta este valor según sea necesario
  }
  .form-group {
    .control-label1 {
        margin-left: 7.5px; 
    }
  }