.chat-container {
    position: fixed;
    bottom: 10px;
    left: 250px;
    height: auto;
    z-index: 1000;
    transition: all 0.3s;
  }
  .chat-toggle-btn {
    width: 100%;
  }
  
  .chat-box {
    background-color: white;
    border: 1px solid var(--tb-orange-uapa);
    border-radius: 5px 5px 0 5px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    padding: 10px;
    margin-top: 10px;
    max-height: 500px;
    max-width: 340px;
    overflow-y: auto;
    position: relative;
  }
  
  .chat-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid var(--tb-orange-uapa);
    padding-bottom: 5px;
    margin-bottom: 10px;
  }
  
  .chat-header-title{
    font-weight: bold;
    font-size: 18px;
    color: var(--tb-orange-uapa);
  }
  
  .chat-header-icons {
    display: flex;
    gap: 10px;
  }

  .chat-header-icons svg {
    cursor: pointer;
    color: var(--tb-orange-uapa);
  
    &:hover {
      color: var(--tb-orange-uapa-text-emphasis);
    }
  }
  
  .chat-body {
    max-height: 300px;
    overflow-y: auto;
  }
  
  .list-group-item {
    word-wrap: break-word; 
  }
  .bot-message {
    background-color: var(--tb-blue-uapa);
    color: white;
    text-align: left;
    border-radius: 10px;
    padding: 10px;
    margin-bottom: 5px;
  }
  
  .user-message {
    background-color: #f3f3f3;
    color: #041147;
    text-align: right;
    border-radius: 10px;
    padding: 10px;
    margin-bottom: 5px;
  }
  .list-group-flush{
    border-radius: 5px;
  }

  .chat-body .list-group-item {
    white-space: pre-wrap;
}
  
  .form-control:focus {
      border: 1px solid var(--tb-blue-uapa);
  }
  
  .hello-message {
    position: absolute;
    top: -30px;
    left: 50%;
    transform: translateX(-50%);
    background-color: var(--tb-blue-uapa);
    color: white;
    padding: 10px 15px;
    border-radius: 10px;
    font-size: 14px;
    z-index: 1001;
    text-align: center;
    &:after {
        content: "";
        position: absolute;
        bottom: -20px;
        left: 50%;
        transform: translateX(-50%);
        border-width: 10px;
        border-style: solid;
        border-color: var(--tb-blue-uapa) transparent transparent transparent;
    }
  }


