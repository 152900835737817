.home {
  h2 {
    color: rgb(255, 255, 255);
    background-color: rgb(4, 37, 94);
    padding: 20px;
  }
}

.col-home-cards {
  margin-top: 20px;
  .card {
    border-radius: 12px;
    transition-property: all;
    transition-duration: 300ms;
    transition-timing-function: linear;
    transition-delay: 75ms;
    box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2px;
    
    h3 {
      margin-bottom: 10px;
    }
  }
}