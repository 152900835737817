/*
Template Name: Steex - Admin & Dashboard Template
Author: Themesbrand
Website: https://themesbrand.com/
Contact: support@themesbrand.com
File: Custom Css File
*/

.custom-box {
  @media (min-width: 576px) and (max-width: 767.98px) {
    margin: auto;
    max-width: 95%;
  }
}